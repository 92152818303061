import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Container, Typography, Grid } from "@mui/material";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay, Mousewheel, Keyboard } from "swiper";
import { graphql, useStaticQuery } from "gatsby";

const Carousel = () => {
  const data = useStaticQuery(graphql`
    query {
      carrouselImages: allFile(
        filter: { relativePath: { regex: "/areas/" } }
        sort: { fields: relativePath }
      ) {
        nodes {
          childrenImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
          name
        }
      }
    }
  `);

  return (
    <Box marginBottom={{ xs: 6, sm: 10 }} marginTop={{ xs: 6, sm: "5em" }}>
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Typography variant="h2" fontWeight={"bold"} paddingBottom={4}>
              Relevant for different specialties
            </Typography>
          </Grid>
        </Grid>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            400: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          spaceBetween={0}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={true}
          cssMode={true}
          navigation={true}
          pagination={false}
          mousewheel={false}
          modules={[Navigation, Pagination, Autoplay, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          {data.carrouselImages.nodes.map((image, index) => (
            <SwiperSlide key={index}>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                }}
              >
                <GatsbyImage
                  image={image.childrenImageSharp[0].gatsbyImageData}
                  alt={image.name}
                />
              </div>
              <div className="areas_block">
                <div
                  style={{
                    color: "white",
                    background: "rgb(88 88 88 / 50%)",
                    padding: "10px 0px",
                  }}
                >
                  {image.name}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  );
};

export default Carousel;
