import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import first from "/src/images/accordion/best_quality.webp";
import second from "/src/images/accordion/attach.webp";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `0.5px solid white`,
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "white" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgb(32, 32, 38)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "white",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "white",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "#FFA100",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #FFA100",
  color: "white",
  backgroundColor: "#202026",
}));

export default function FAQsSection(data) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section id="FAQ-microREC">
      <Grid container spacing={2} paddingBottom={4}>
        <Grid item paddingTop={4} paddingBottom={4} xs={12}>
          <h2 style={{ color: "white", paddingBottom: "4", fontSize: "28px" }}>
            Much more than an
            <b style={{ color: "#FFA100", paddingBottom: "4" }}>
              {" "}
              optical system
            </b>
          </h2>
          <Typography variant="h6" color="white">
            You need a system that fits your busy routine and helps you to{" "}
            <i>
              <b>
                <a
                  href="/21-Digital-Transformation-in-Ophthalmology-Recording-and-Sharing-Live-Surgery/"
                  target="_blank"
                  className="links_post"
                >
                  improve your workflow
                </a>{" "}
                with the best quality recordings.{" "}
              </b>
            </i>
            No cables, USB devices, less time spent transferring your media.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel3bh-header"
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                01. Best quality - up to 8k
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                You don’t need to update your recording system every five years
                and keep it outdated. Smartphones have every year better
                cameras.
              </Typography>
              <Link
                to="/18-3-Best-Smartphones-to-Record-Microscope-Procedures/"
                className="links_post"
              >
                The best smartphones for recording &#8594;
              </Link>
              <img
                src={first}
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "cover",
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
                alt=""
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel3bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                02. Attach in less than one minute
              </h3>
            </AccordionSummary>
            <AccordionDetails id="" className="AccordionSummary2">
              <Typography sx={{ padding: 1 }}>
                Forget waiting for engineers to install your recording system.
                You can do it easily in a few steps.
              </Typography>

              <GatsbyImage
                image={getImage(data.data.faqImage2)}
                style={{
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#00C5B7",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                03. It does not block the binoculars
              </h3>
            </AccordionSummary>
            <AccordionDetails className="AccordionSummary3">
              <Typography sx={{ padding: 1 }}>
                Keep your and your colleagues workflow uninterrupted with
                MicroREC away from the binoculars.
              </Typography>

              <GatsbyImage
                image={getImage(data.data.faqImage3)}
                style={{
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                04. Compatible with 95% of the microscopes
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                The adaptors and universal holder allow MicroREC to attach to
                95% of the microscopes and slit lamps, and 100% of the
                smartphones available in the market.
              </Typography>
              <Link to="/compatibility" className="links_post">
                Check out if yout device is compatible! &#8594;
              </Link>
              <div
                style={{ fontSize: "12px", color: "#616161", marginTop: "1em" }}
              >
                <b>Disclaimer:</b> Our product, MicroREC, is a registered,
                independent innovation by Custom Surgical GmbH. While it is
                compatible with third-party equipment, Custom Surgical is not
                affiliated with, nor endorsed by, any of these brands. All
                trademarks are the property of their respective owners, and any
                references are made purely for compatibility purposes.
              </div>
              <GatsbyImage
                image={getImage(data.data.faqImage4)}
                style={{
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                05. Compact and Portable
              </h3>
            </AccordionSummary>
            <AccordionDetails className="AccordionSummary2">
              <Typography sx={{ padding: 1 }}>
                You are working in different clinics, or you find the most
                exciting cases in the field: that's for you. A recording system
                weighs less than a kg, and you can bring it anywhere with its
                traveler's case.
              </Typography>
              <Link
                to="/microrec-app#Carousel"
                className="links_postvio"
              ></Link>
              <Link to="/microrec-app#Carousel" className="links_postvio">
                Check out some videos! &#8594;
              </Link>

              <GatsbyImage
                image={getImage(data.data.faqImage5)}
                style={{
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </section>
  );
}
