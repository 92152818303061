import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import Accordion from "../components/microrec/accordion/accordion";
import Kit from "../components/microrec/kit/kit";
import Areas from "../components/microrec/areas";
import Subscribe from "../components/misc/check_list";
import Tabs from "../components/main/tabs";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Grid } from "@mui/material";
import Compatibility from "../components/microrec/compatibility";
import Connect from "../components/microrec/bundle";
import Reviews from "../components/main/reviews";
import { StaticImage } from "gatsby-plugin-image";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = (
    <StaticImage
      src="../images/iso_color.png"
      alt="Logo"
      loading="eager"
      formats={["auto", "webp", "avif"]}
    />
  );
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo
          title="Slit Lamp and Microscope Adapter - Med Imaging - MicroREC | Custom Surgical"
          description="Record, broadcast, or share your procedures up to 8K quality without any cables. MicroREC has German lenses to enhance the quality, and you can attach it in seconds."
        />{" "}
        <Subscribe />
        <>
          <div className="image-container">
            <StaticImage
              src="../images/microrec/background.png"
              alt="Main Image"
              loading="eager"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              quality={100}
              style={{
                objectFit: "cover",
                aspectRatio: "2 / 1",
                width: "100%",
                height: "auto",
                height: "80vh",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                backgroundSize: "cover",
                width: "100%",
              }}
            />{" "}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
            ></div>
            <div className="text-overlay">
              <h1 className="text_over_microrec_page">
                MICROREC
                <Grid item xs={10} sm={7}>
                  <div
                    style={{
                      fontSize: "30px",
                      marginTop: "1em",
                      color: "white",
                      fontWeight: "500",
                    }}
                  >
                    The ultimate optical system to{" "}
                    <b>digitize your microscope or slit lamp</b>
                  </div>
                </Grid>
                <button className="shop" style={{ marginTop: "3em" }}>
                  <a href="/store" style={{ color: "white", fontSize: "24px" }}>
                    Shop now
                  </a>
                </button>
              </h1>
            </div>
          </div>
          <Kit />
          <Accordion />
          <Reviews />
          <Tabs />
          <Areas />
          <Connect />
          <Compatibility />
        </>{" "}
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
