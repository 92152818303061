import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import micro from "../../../images/microrec/MicroREC.webp";
import without from "../../../images/microrec/MicroREC AD.webp";
import withbm from "../../../images/microrec/MicroREC BS.webp";
import { Container, Grid } from "@mui/material";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { StaticImage } from "gatsby-plugin-image";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container justifyContent="space-between" padding={2}>
          {children}
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container
      maxWidth="lg"
      paddingTop="3em"
      style={{ paddingBottom: "3em", marginTop: "5em" }}
    >
      <Grid container>
        <Grid item xs={12} paddingBottom={4}>
          <Typography variant="h2" fontWeight={"bold"} paddingBottom={4}>
            Components that will upgrade your&nbsp;workflow
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" paddingBottom={6}>
            Our solution delivers all the data you need from your microscope or
            slit lamp in a few components.
            <br />
            Get to know what will arrive for you:
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent={"flex-end"}>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            className="tabs"
            TabIndicatorProps={{ style: { background: "#8F4FFF" } }}
            variant="fullWidth"
          >
            <Tab
              style={{ color: "#202026" }}
              label="MICROREC"
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: "#202026" }}
              label="MICROREC WITH BEAMSPLITTER"
              {...a11yProps(1)}
            />
            <Tab
              style={{ color: "#202026" }}
              label="MICROREC WITH ADAPTER"
              {...a11yProps(2)}
            />
          </Tabs>
        </Grid>
        <TabPanel value={value} index={0}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              loading="lazy"
              src={micro}
              style={{ width: "100%", objectFit: "cover", borderRadius: "5px" }}
            ></img>
          </Grid>
          <Grid container item xs={12} sm={7}>
            <Grid item xs={12} padding={1}>
              <p>
                This is where the magic happens. MicroREC is assembled with{" "}
                <b>german lenses</b> that guarantee <b>the best quality</b> for
                your videos and pictures. In addition, it is compatible with{" "}
                <b>any smartphone </b>you have
                <b> without blocking the binoculars. </b>
              </p>
            </Grid>
            <Grid
              container
              xs={12}
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item xs={10} sm={6}>
                <Grid item xs={12} padding={1}>
                  <AnchorLink to="/compatibility" className="links_post">
                    Check the compatiblity!
                  </AnchorLink>
                </Grid>
                <Grid item xs={12} padding={1} marginTop={2}>
                  <a href="/store/microrec">
                    <button className="shop">SHOP NOW</button>
                  </a>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <StaticImage
                  src="../../../images/microrec/logo1.png"
                  alt="Logo"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ maxWidth: "50%" }}
                />
                <StaticImage
                  src="../../../images/microrec/logo2.png"
                  alt="Logo"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ maxWidth: "50%" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              loading="lazy"
              src={withbm}
              style={{ width: "100%", objectFit: "cover", borderRadius: "5px" }}
            ></img>
          </Grid>
          <Grid container item xs={12} sm={7}>
            <Grid item xs={12} padding={1}>
              <p>
                It attaches to a microscope or slit lamp,{" "}
                <b>captures a beam of light</b>, and directs it to the
                smartphone camera. It is also a key to{" "}
                <b>increase the compatibility of MicroREC</b>.
              </p>
            </Grid>

            <Grid
              container
              xs={12}
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item xs={10} sm={6}>
                <Grid item xs={12} padding={1}>
                  <AnchorLink
                    to="/microrec#Compatibility"
                    className="links_post"
                  >
                    Check the compatiblity!
                  </AnchorLink>
                </Grid>
                <Grid item xs={12} padding={1} marginTop={2}>
                  <a href="/store/microrec">
                    <button className="shop">SHOP NOW</button>
                  </a>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <StaticImage
                  src="../../../images/microrec/logo1.png"
                  alt="Logo"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ maxWidth: "50%" }}
                />
                <StaticImage
                  src="../../../images/microrec/logo2.png"
                  alt="Logo"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ maxWidth: "50%" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={without}
              loading="lazy"
              style={{ width: "100%", objectFit: "cover", borderRadius: "5px" }}
            ></img>
          </Grid>
          <Grid container item xs={12} sm={7}>
            <Grid item xs={12} padding={1}>
              <p>
                The adaptor is the key to attaching MicroREC to most microscopes
                worldwide. It is used mainly{" "}
                <b>if you already have a beam splitter</b>.
              </p>
            </Grid>

            <Grid
              container
              xs={12}
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item xs={10} sm={6}>
                <Grid item xs={12} padding={1}>
                  <AnchorLink
                    to="/microrec#Compatibility"
                    className="links_post"
                  >
                    Check the compatiblity!
                  </AnchorLink>
                </Grid>
                <Grid item xs={12} padding={1} marginTop={2}>
                  <a href="/store/microrec">
                    <button className="shop">SHOP NOW</button>
                  </a>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <StaticImage
                  src="../../../images/microrec/logo1.png"
                  alt="Logo"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ maxWidth: "50%" }}
                />
                <StaticImage
                  src="../../../images/microrec/logo2.png"
                  alt="Logo"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ maxWidth: "50%" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Container>
  );
}
