import React, { useEffect } from "react";

import Video from "./video/video";
import { Grid } from "@mui/material";
import { useStaticQuery, graphql } from "gatsby";

import "swiper/css";
import "swiper/css/pagination";

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      allReviewJson(sort: { fields: order }) {
        nodes {
          name
          description
          link
          linkText
          video {
            publicURL
          }
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
            publicURL
          }
        }
      }
    }
  `);
  const reviews = data.allReviewJson.nodes;

  const storiesVideos = [];
  const getStoriesdata = () => {
    for (const id in reviews) {
      const item = {
        url: reviews[id].video.publicURL,
        type: "video",
      };

      storiesVideos.push(item);
    }

    return storiesVideos;
  };

  useEffect(() => {
    const storiesVideos = getStoriesdata();
  }, []);

  return (
    <>
      <section
        id="testimonials"
        style={{
          maxWidth: "1500px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "5em",
          marginBottom: "5em",
        }}
      >
        <div id="users_testimonials" sx={styles.testimonialSection}></div>
        <div style={flexReview}>
          <Grid
            container
            justifyContent="space-around"
            marginTop={{ xs: 0, sm: 0 }}
          >
            <Grid
              xs={10}
              sm={4}
              item
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
              className={styles.card}
            >
              <Grid
                className="text_flex_"
                item
                fontSize={{ md: "40px", sm: "30px", xs: "20px" }}
                borderTopLeftRadius="20px"
                borderBottomLeftRadius="30px"
                style={{ display: "flex", alignItems: "center" }}
              >
                The unique experience of those who choose the&nbsp;future.
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              sm={7}
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-start",
              }}
              justifyContent={"space-around"}
              id="carousel_review"
            >
              {reviews.map((review, index) => {
                const name = review.name;
                const description = review.description;
                const link = review.link;
                const linkText = review.linkText;
                return (
                  <Grid
                    xs={10}
                    md={4}
                    item
                    style={{
                      position: "relative",
                      display: "flex",
                      marginRight: "16px",
                    }}
                    className={styles.card}
                  >
                    <div style={block}>
                      <img src={review.image.publicURL} style={imgt}></img>
                      <div style={blockText}>
                        <div style={blockH1}>{name}</div>
                        <div style={blockp}>{description}</div>
                      </div>
                    </div>

                    <Video stories={storiesVideos} currentIndex={index}></Video>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default Reviews;

const flexReview = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const block = { position: "relative", textAlign: "left" };
const imgt = {
  borderRadius: "10px",
};
const blockText = { width: "90%" };
const blockH1 = {
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "24px",
  letterSpacing: "0.05px",
};
const blockp = { fontStyle: "italic", fontSize: "13px" };

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "space-between",
  },
  testimonialSection: {
    py: [8, null, 9, null, null, 10],
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "flex-start",
  },
  sectionHeading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: -1,
    marginBottom: ["50px", null, "60px", null, "65px", "75px"],
    mb: [7, null, null, 8, 9, 10],
    h2: {
      color: "primary",
      fontSize: [5, null, null, 8],
      letterSpacing: ["1px"],
    },
    p: {
      color: "#858B91",
      fontSize: [2, null, null, 5],
      m: ["15px auto 0", null, null, "10px auto 0"],
      maxWidth: "760px",
    },
  },
  sectionHeading_industry: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pt: [6],
    pb: [6],
    mb: [0],
    p: {
      color: "#858B91",
      fontSize: [2, null, null, 5],
      maxWidth: "760px",
    },
  },
  carouselWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
    mt: "-35px",
    px: "15px",
    ".carousel-container": {
      width: "100%",
      maxWidth: [
        "100%",
        "600px",
        "900px",
        "1000px",
        "1200px",
        "1400px",
        null,
        "calc(50% + 865px)",
      ],
      mr: ["auto", null, null, null, null, null, null, "-220px"],
      ml: "auto",
    },
    ".react-multi-carousel-list": {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      position: "relative",
    },
    ".react-multi-carousel-item": {
      transition: "all 0.25s",
    },
    ".react-multi-carousel-item--active:nth-of-type(4n)": {},
    ".react-multi-carousel-track": {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      position: "relative",
      transformStyle: "preserve-3d",
      backfaceVisibility: "hidden",
    },
  },
  reviewCard: {
    testimonial: {
      overflow: "hidden",
    },
    boxShadow: "0px 0px 1px rgba(38, 78, 118, 0.35)",
    transition: "all 0.3s",
    borderRadius: "6px",
    p: [
      "30px 20px 35px",
      "30px 25px 35px",
      "30px 20px 35px",
      "35px 30px 40px 40px",
      "30px 30px 35px",
      "35px 30px 40px 40px",
    ],
    bg: "white",
    textAlign: "left",
    m: [
      "28px 5px 30px 5px",
      "28px 20px 30px 20px",
      "28px 15px 30px 15px",
      "28px 15px 30px 15px",
      "30px 20px 40px",
    ],
    "&:hover": {
      boxShadow: "0px 6px 47px rgba(38, 78, 118, 0.1)",
    },
    ".rating": {
      mb: [1, null, null, 2],
      ul: {
        pl: 0,
        listStyle: "none",
        mb: 0,
        display: "flex",
      },
      svg: {
        marginRight: "2px",
        "&:last-of-type": {
          marginRight: 0,
        },
      },
      ".star": {
        color: "yellow",
        mr: "1px",
      },
      ".star-o": {
        color: "#ddd",
        mr: "1px",
      },
    },
    ".card-footer": {
      display: "flex",
      alignItems: "center",
      marginTop: [5, null, null, "33px"],
      ".image": {
        flexShrink: 0,
        mr: [3, null, null, 4],
        display: "flex",
        img: {
          width: "75px",
          height: "75px",
          borderRadius: "50%",
          objectFit: "cover",
        },
      },
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    mb: "18px",
    color: "text",
    lineHeight: 1.6,
  },
  description: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "italic",
    color: "text",
  },
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    mb: "14px",
    color: "text",
  },
  designation: {
    color: "primary",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: 1.4,
  },
  industrySection: {
    mb: ["30px"],
  },
  sponsoredBy: {
    alignItems: "center",
    flexFlow: "row wrap",
    justifyContent: "space-evenly",
    maxWidth: "760px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    gap: "30px",
  },
  logo: {
    height: "39px",
    "@media screen and (max-width: 450px)": {
      height: "29px",
    },
  },
};
