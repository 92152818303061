import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";

const Download = () => {
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#8F4FFF",
        paddingBottom: "2em",
      }}
    >
      <Container xs={12} maxWidth={"lg"}>
        <Grid
          container
          xs={12}
          justifyContent={{ xs: "center", sm: "space-between" }}
          style={{
            position: "relative",
            zIndex: "3",
          }}
        >
          <Grid
            item
            sm={5}
            xs={10}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="./2.png"
              style={{
                maxHeight: "270px",
                maxWidth: "100%",
              }}
              imgStyle={{
                objectFit: "contain",
              }}
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
            />
            <StaticImage
              src="./MicroREC White Logo 1.png"
              style={{ maxWidth: "100%" }}
              imgStyle={{
                objectFit: "contain",
              }}
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
            />

            <div
              style={{
                fontSize: "30px",
                fontWeight: "20px",
                color: "white",
                fontWeight: "600",
                marginTop: "1em",
                textAlign: "center",
              }}
            >
              Outstanding alone
            </div>
          </Grid>
          <Grid
            item
            sm={5}
            xs={10}
            marginTop={{ xs: 5, sm: 0 }}
            marginBottom={{ xs: 7, sm: 5, md: 0 }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StaticImage
              src="./3.png"
              style={{
                maxHeight: "250px",
                maxWidth: "100%",
              }}
              imgStyle={{
                objectFit: "contain",
              }}
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
            />
            <StaticImage
              src="./connect.png"
              style={{ maxWidth: "100%" }}
              imgStyle={{
                objectFit: "contain",
              }}
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
            />
            <div
              style={{
                fontSize: "30px",
                fontWeight: "20px",
                color: "white",
                fontWeight: "600",
                marginTop: "1em",
                textAlign: "center",
              }}
            >
              Even better together
            </div>
          </Grid>{" "}
        </Grid>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            position: "relative",
            zIndex: "3",
          }}
        >
          <a
            href="/connect"
            target="_blank"
            style={{
              backgroundColor: "white",
              padding: "10px 20px",
              color: "#8F4FFF",
              borderRadius: "20px",
              fontWeight: "600",
            }}
          >
            DISCOVER MORE
          </a>
        </div>
      </Container>

      <StaticImage
        src="./background.png"
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
          zIndex: 1,
          maxHeight: "470px",
        }}
        imgStyle={{
          objectFit: "cover",
        }}
        quality={100}
        loading="lazy"
        placeholder="dominantColor"
        formats={["auto", "webp", "avif"]}
      />
    </div>
  );
};

export default Download;
